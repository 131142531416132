import React from 'react'
import {Link} from 'gatsby'
import Tilt from 'react-parallax-tilt'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Grab from '../assets/grab.svg'
import styled from 'styled-components'
import tachyons from 'tachyons-components'
import { format } from 'date-fns'

const ProjectSection = tachyons('div')`
w-100 mt0
`
const ProjectContainer = tachyons('div')`
mb6 cf
`
const ProjectFeatureImage = tachyons('div')`
image-frame dib fr-ns w-100 w-40-l tc
`
const ProjectContent = tachyons('div')`
dib fl-ns w-100 w-50-l mt3 mt4-l
`
const ButtonSecondary = tachyons(Link)`
button w-auto no-underline br2 ph4 pv3 mt2 dib tc bg-secondary bs-secondary br-secondary
`
const ProjectListContainer = tachyons('div')`
w-100-ns
`
const ProjectMeta = tachyons('h4')`
f6 mb3 mt0
`
const StyledProjectMeta = styled(ProjectMeta)`
  color: var(--textSecondary);
  font-weight: var(--fontNormal);
`
const StyledGrab = styled(Grab)`
  width: 32px;
  vertical-align: text-bottom;
`

const ProjectTitle = tachyons('h3')`
lh-copy mb1 mt0 fw6
`
const ProjectExcerpt = tachyons('p')`
lh-copy mt1 mb3
`


const ProjectPostCard = ({posts}) => {
  return (
    <ProjectSection>
      {posts &&
        posts
          .slice(0, posts.length)
          .filter(post => post.node.frontmatter.templateKey === "project-post")
          .map(({ node: post }) => (
            <ProjectContainer>
              <ProjectFeatureImage>
                <Tilt
                  tiltReverse={true}
                  tiltMaxAngleX="1.5"
                  tiltMaxAngleY="5.5"
                  gyroscope={true}
                  glareMaxOpacity="0.2"
                  glareReverse={true}
                  glareEnable={true}
                >
                  <PreviewCompatibleImage
                    imageInfo={post.frontmatter.featuredImage}
                  />
                </Tilt>
              </ProjectFeatureImage>
              <ProjectContent key={post.id}>
                <ProjectListContainer>
                  <ProjectTitle>{post.frontmatter.title}</ProjectTitle>
                  <StyledProjectMeta>
                    <StyledGrab/> · {post.frontmatter.type}{" "}
                    {format(new Date(post.frontmatter.date), "MMMM yyyy")}
                  </StyledProjectMeta>
                  <ProjectExcerpt>
                    {post.frontmatter.description}
                  </ProjectExcerpt>
                  <ButtonSecondary role="button" to={post.fields.slug}>
                    Read more details →
                  </ButtonSecondary>
                </ProjectListContainer>
              </ProjectContent>
            </ProjectContainer>
          ))}
    </ProjectSection>
  );
}

export default ProjectPostCard