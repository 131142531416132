import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ProjectList from '../components/ProjectList'
// import ProjectFeatured from '../components/ProjectFeatured'
import tachyons from 'tachyons-components'
import Helmet from 'react-helmet'
import GlobalStyle from "../styles/GlobalStyle"

const PageContainer = tachyons('div')`
pv4-ns ph3 ph4-ns
`
const PageHeaderContainer = tachyons('div')`
dt wrap pt5 pt6-ns pb5
`
const PageHeadingsContainer = tachyons('div')`
fl w-100 w-30-l tl
`
const SectionDivider = tachyons('section')`
w-100 dt
`
const Divider = tachyons('div')`
wrap hr-top
`
const PageHeadings = tachyons('h2')`
f3 fw6 mb2 mt0
`
const PageDescriptions = tachyons('p')`
fl w-100 w-70-l lh-copy mb0
`
const SectionArticles = tachyons('div')`
w-100 dt mt5
`
const ArticleWrapper = tachyons('div')`
wrap
`

export default class ProjectIndexPage extends React.Component {
  render() {

    return (
      <Layout>
        <GlobalStyle/>
        <Helmet
          titleTemplate="Projects · Ajmal Afif">
          <meta name='description' content='Featured projects & experiments I‘ve worked on in recent times. Sensitive informations in some projects are redacted for privacy and confidentiality purposes.' />
          <meta name="twitter:title" content={`Projects · Ajmal Afif`} />
          <meta name="twitter:description" content='Featured projects & experiments I‘ve worked on in recent times. Sensitive informations in some projects are redacted for privacy and confidentiality purposes.' />
          <meta property="og:title" content={`Projects · Ajmal Afif`} />
          <meta property="og:description" content='Featured projects & experiments I‘ve worked on in recent times. Sensitive informations in some projects are redacted for privacy and confidentiality purposes.' />
      </Helmet>
        <PageContainer>
          <PageHeaderContainer>
            <PageHeadingsContainer>
              <PageHeadings>
                Projects
              </PageHeadings>
            </PageHeadingsContainer>
            <PageDescriptions>
            Featured projects & experiments I‘ve worked on in recent times. Sensitive informations in some projects are redacted for privacy and confidentiality purposes.
            </PageDescriptions>
          </PageHeaderContainer>
          <SectionDivider>
            <Divider />
          </SectionDivider>
          <SectionArticles>
            <ArticleWrapper>
              <ProjectList posts={this.props.data.allMarkdownRemark.edges} />
            </ArticleWrapper>
          </SectionArticles>
        </PageContainer>
      </Layout>
    )
  }
}

ProjectIndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const ProjectIndexQuery = graphql`
query ProjectIndexQuery {
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "project-post"}}}) {
    edges {
      node {
        excerpt(pruneLength: 150)
        id
        fields {
          slug
        }
        frontmatter {
          templateKey
          title
          description
          featuredImage {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 640, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          type
          tags
          date(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
}
`